//Retailer 

//Sim Activation

export const GET_CHECK_SIM = 'GET_CHECK_SIM';
export const GET_PHONE_NUMBER = 'GET_PHONE_NUMBER';
export const LOCK_PHONE_NUMBER ='LOCK_PHONE_NUMBER' ;
export const MANAGE_SIM_ACTIVATION = 'MANAGE_SIM_ACTIVATION';
export const GENERATE_QR_CODE ='GENERATE_QR_CODE';
export const GENERATE_MOBILE_PRINTOUT ='GENERATE_MOBILE_PRINTOUT';
export const ALTEN_TRANSACTIONS ='ALTEN_TRANSACTIONS';
export const ADD_ALTEN_TRANSACTION ='ADD_ALTEN_TRANSACTION';
export const UPDATE_ALTEN_TRANSACTION ='UPDATE_ALTEN_TRANSACTION';
export const VERIFY_ALTEN_TRANSACTION ='VERIFY_ALTEN_TRANSACTION';
export const GET_STATIC_ICCID ='GET_STATIC_ICCID';
export const GET_PORTIN_CUSTOMER_NUMBER = 'GET_PORTIN_CUSTOMER_NUMBER';
export const ALTEN_INDIVIDUAL_TRANSACTION ='ALTEN_INDIVIDUAL_TRANSACTION';
export const GET_PORTIN_STATUS ='GET_PORTIN_STATUS';
export const GET_PORTOUT_STATUS ='GET_PORTOUT_STATUS';

//Voucher 

export const GET_VOUCHER = 'GET_VOUCHER'
export const PURCHASE_VOUCHER = 'PURCHASE_VOUCHER'
export const CHECK_MOBILE_NUMBER ='CHECK_MOBILE_NUMBER'
export const DIRECT_PIN_TOPUP ='DIRECT_PIN_TOPUP'

//Services

export const TRANFER_BALANCE= 'TRANFER_BALANCE'
export const SIM_SWAP= 'SIM_SWAP'
export const CHANGE_OPTION='CHANGE_OPTION';

// Report

export const DAILY_SALES_REPORT = 'DAILY_SALES_REPORT'
export const DASHBOARD_REPORT='DASHBOARD_REPORT'
export const GET_PROFIT_REPORT=' GET_PROFIT_REPORT'
export const DAILY_SALES_HISTORY = 'DAILY_SALES_HISTORY'
export const DAILY_SALES_REPRINT = 'DAILY_SALES_REPRINT'

// Customer Info
export const GET_CUSTOMER_INFO = 'GET_CUSTOMER_INFO';
//RETAILER
export const GET_RETAILER=' GET_RETAILER'
export const GET_RETAILER_INFORMATION=' GET_RETAILER_INFORMATION'
export const UPDATE_RETAILER_USER='UPDATE_RETAILER_USER'
export const DELETE_USER='DELETE_USER'

/* portIn Request */
export const MANAGE_MNP_PORTIN = "MANAGE_MNP_PORTIN";
export const PORTIN_REQUEST_NOTIFICATION = "PORTIN_REQUEST_NOTIFICATION";

