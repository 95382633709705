import { PREACTIVATE_SIM, VERIFY_PREACTIVATION, GET_PREACTIVATION_LIST } from 'redux/admin-constants';

const initialState = { 
  simPreactivationDetails:{},
  verificationData:{},
  preactivationListData:{},
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {

  case PREACTIVATE_SIM: {
    return {
      ...state,
      simPreactivationDetails: payload,
    }
  }

  case VERIFY_PREACTIVATION: {
    return {
      ...state,
      verificationData: payload,
    }
  }

  case GET_PREACTIVATION_LIST: {
    return {
      ...state,
      preactivationListData: payload,
    }
  }

  default:
    return state;
  }
}

export default reducer;