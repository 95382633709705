import {
  GET_SIM_ACTIVATED_LIST_REPORT,
  GET_TOPUP_HISTORY_REPORT,
  GET_SIM_ACTIVATED_LIST_PDF,
  GET_SIM_ACTIVATED_LIST_SM,
  USAGE_REPORT,
  VOUCHER_USAGE_REPORT,
} from 'redux/admin-constants';
const initialState = {
  summaryData: {},
  summaryDataPdf: [],
  topupHistory: [],
  usageReport: [],
  simActivatedListDataSM: {},
  voucherusageReport:[],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SIM_ACTIVATED_LIST_REPORT: {
      return {
        ...state,
        summaryData: payload,
      };
    }
    case GET_SIM_ACTIVATED_LIST_PDF: {
      return {
        ...state,
        summaryDataPdf: payload,
      };
    }
    case GET_TOPUP_HISTORY_REPORT: {
      return {
        ...state,
        topupHistory: payload,
      };
    }
    case GET_SIM_ACTIVATED_LIST_SM: {
      return {
        ...state,
        simActivatedListDataSM: payload,
      };
    }
    case USAGE_REPORT: {
      return {
        ...state,
        usageReport: payload,
      };
    }
    case GET_SIM_ACTIVATED_LIST_SM: {
      return {
        ...state,
        simActivatedListDataSM: payload,
      };
    }
    
    case VOUCHER_USAGE_REPORT: {
      return {
        ...state,
        voucherusageReport: payload,
      };
    }

    default:
      return state;
  }
};
export default reducer;
