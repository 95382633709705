import {
  SET_DATA, 
  SIM_DATA, 
  REVERSE_PINSTOCK_DATA,
  MSISDN_DATA,
  TARIFF_BUNDLE_DATA,
  CASH_VOUCHER_DATA,
  DISTRIBUTOR_PINS_DATA
} from 'redux/admin-constants';

const reducer = (state = {}, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {

    case SET_DATA: {
      return {
        ...state,
        todaysStockReport: payload,
      }
    }

    case SIM_DATA: {
      return {
        ...state,
        todaysStockReportSim: payload,
      }
    }

    case REVERSE_PINSTOCK_DATA: {
      return {
        ...state,
        todaysStockReportReversePinstock: payload,
      }
    }

    case MSISDN_DATA: {
      return {
        ...state,
        todaysStockReportMsisdn: payload,
      }
    }

    case TARIFF_BUNDLE_DATA: {
      return {
        ...state,
        todaysStockReportTariffBundle: payload,
      }
    }

    case CASH_VOUCHER_DATA: {
      return {
        ...state,
        todaysStockReportCashVoucherData: payload,
      }
    }

    case DISTRIBUTOR_PINS_DATA: {
      return {
        ...state,
        todaysStockReportDistributorPins: payload,
      }
    }
    
    default:
      return state;
  }
};

export default reducer;