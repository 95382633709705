const { GET_MARQUEE_LIST, INSERT_MARQUEE_DETAILS, UPDATE_MARQUEE_DETAILS, GET_CARD_MARQUEE_LIST} = require("redux/admin-constants");

const initialState = {
  marqueeOption: [],
  cardMarqueeOption: [],
}

const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case GET_MARQUEE_LIST: {
      return {
        ...state,
        marqueeOption: payload,
      }
    }

    case GET_CARD_MARQUEE_LIST: {
      return {
        ...state,
        cardMarqueeOption: payload,
      }
    }

    case INSERT_MARQUEE_DETAILS: {
      return { ...state, marqueeOption: [payload, ...state.marqueeOption] }
    }
   
    case UPDATE_MARQUEE_DETAILS: {
      { 
        return {
          ...state,
          marqueeOption: payload,
        }
      }
    }

    default:
      return state;
  }
}

export default reducer;