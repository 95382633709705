import { GET_BULK_ORDER } from '../constants';

const initialState = {
  bulkOrder: [],
  getBulk: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BULK_ORDER: {
      return {
        ...state,
        getBulk: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
