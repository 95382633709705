import { GET_SECURITY_QUESTIONS } from '../constants';

const initialState = {
  questions: [],
  questionsData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SECURITY_QUESTIONS: {
      return {
        ...state,
        questions: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
