import {
  GET_SIM_AND_VOUCHER_SALE_REPORT,
  GET_SIM_AND_VOUCHER_SALE_REPORT_PDF,
} from 'redux/admin-constants';
const initialState = {
  simAndVoucherReport: [],
  simAndVoucherReportPdf: [],
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SIM_AND_VOUCHER_SALE_REPORT: {
      return {
        ...state,
        simAndVoucherReport: payload,
      };
    }
    case GET_SIM_AND_VOUCHER_SALE_REPORT_PDF: {
      return {
        ...state,
        simAndVoucherReportPdf: payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;
