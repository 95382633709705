import { INSERT_WORKGROUP, UPDATE_WORKGROUP, GET_WORKGROUP, DELETE_WORKGROUP } from 'redux/admin-constants';

const initialState = { 
  workGroupData:[],
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {

  case GET_WORKGROUP: {
    return {
      ...state,
      workGroupData: payload,
    }
  }

  case INSERT_WORKGROUP: {
    return { ...state, workGroupData:[...state.workGroupData, payload] }
  }

  case UPDATE_WORKGROUP: {
    return {
      ...state, 
      workGroupData:
       state.workGroupData.map(item => item.workgroupid === payload.workgroupid ? 
        { ...item, ...payload} : item 
      )
    }
  }

  case DELETE_WORKGROUP:
    return { ...state, 
      workGroupData: state.workGroupData.filter(item => item.workgroupid !== payload.workgroupid)
  }

  default:
    return state;
  }
}

export default reducer;