import {
  GET_SIM_STOCK,
  MANAGE_SIM_STOCK,
  GET_SIM_INVENTORY,
  MANAGE_SIM_INVENTORY,
  GET_USER_TYPES,
  CHANGE_SIM_STATUS,
  GET_ICCID,
  GET_SIM_DISTRIBUTION,
  GET_SIM_USER_DISTRIBUTION,
  GET_USER_SIM_STOCK,
  STORE_XML_FILE,
  INSERT_PHONE_NUMBER,
  GET_PHONE_NUMBER,
  GET_MSISDN_LIST,
  GET_MSISDN_UPLOAD_LIST,
} from 'redux/admin-constants';

const initialState = {
  simStockData: [],
  userSimStockData: [],
  simInventoryData: [],
  userTypeList: [],
  iccidData: [],
  changeStatus: {},
  simDistributionData: [],
  manageSimStockData: {},
  storeXmlData: {},
  simUserDistributionData: [],
  msisdnData: [],
  availablePhoneNumber: [],
  msisdnListData: [],
  msisdnUploadList: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SIM_STOCK: {
      return {
        ...state,
        simStockData: payload,
      };
    }

    case GET_SIM_DISTRIBUTION: {
      return {
        ...state,
        simDistributionData: payload,
      };
    }

    case GET_SIM_USER_DISTRIBUTION: {
      return {
        ...state,
        simUserDistributionData: payload,
      };
    }

    case MANAGE_SIM_STOCK: {
      return {
        ...state,
        manageSimStockData: payload,
      };
    }

    case INSERT_PHONE_NUMBER: {
      return {
        ...state,
        msisdnData: payload,
      };
    }

    case GET_PHONE_NUMBER: {
      return {
        ...state,
        availablePhoneNumber: payload,
      };
    }

    case GET_SIM_INVENTORY: {
      return {
        ...state,
        simInventoryData: payload,
      };
    }

    case GET_USER_SIM_STOCK: {
      return {
        ...state,
        userSimStockData: payload,
      };
    }

    case MANAGE_SIM_INVENTORY: {
      return {
        ...state,
        simInventoryData: [payload, ...state.simInventoryData],
      };
    }

    case STORE_XML_FILE: {
      return {
        ...state,
        storeXmlData: payload,
      };
    }

    case GET_USER_TYPES: {
      return {
        ...state,
        userTypeList: payload,
      };
    }

    case CHANGE_SIM_STATUS: {
      return {
        ...state,
        changeStatus: payload,
      };
    }

    case GET_ICCID: {
      return {
        ...state,
        iccidData: payload,
      };
    }

    case GET_MSISDN_LIST: {
      return {
        ...state,
        msisdnListData: payload,
      };
    }
    case GET_MSISDN_UPLOAD_LIST: {
      return {
        ...state,
        msisdnUploadList: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
