import { MANAGE_MNP_PORTIN, PORTIN_REQUEST_NOTIFICATION } from "redux/retailer-constants";

const initialState = { 
  portInRequestData: {},
  portinRequestNotification:{
    message: '',
    data:'',
    isSuccess: 1
  },
}
  
const reducer = (state = initialState, action) => {
  const {
    type,
    payload
  } = action;

  switch (type) {

    case MANAGE_MNP_PORTIN: {
      return {
        ...state,
        portInRequestData: payload,
      };
    }
    case PORTIN_REQUEST_NOTIFICATION:
      return { ...state, 
        portinRequestNotification: (payload.data) ? payload : initialState.portinRequestNotification
      }

    default:
      return state;
    }
}

export default reducer;