const {
  GET_MNP_PROVIDERS,
  UPDATE_MNP_PROVIDERS,
  INSERT_MNP_PROVIDERS,
  DELETE_MNP_PROVIDERS,
} = require('redux/admin-constants');

const initialState = {
  mnpProviders: [],
  manageProviders: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MNP_PROVIDERS: {
      return {
        ...state,
        mnpProviders: payload,
      };
    }
    case UPDATE_MNP_PROVIDERS: {
      const updatedProvider = payload;
      const existingProviders = state.mnpProviders;

      const updatedMnpProviders = existingProviders.map((provider) =>
        provider.provider_id === updatedProvider.provider_id
          ? updatedProvider
          : provider
      );

      return {
        ...state,
        mnpProviders: updatedMnpProviders,
        manageProviders: updatedProvider,
      };
    }

    case INSERT_MNP_PROVIDERS: {
      return {
        ...state,
        mnpProviders: [payload, ...state.mnpProviders],
      };
    }

    case DELETE_MNP_PROVIDERS:
      const updatedProviderList = state.mnpProviders.filter(
        (item) => item.provider_id !== parseInt(payload.providerID)
      );
      return {
        ...state,
        mnpProviders: updatedProviderList,
      };

    default:
      return state;
  }
};

export default reducer;
