import {
  GET_DSP_USER_LIST,
  CHANGE_STATUS,
  CHANGE_STATUS_SALES,
  CHANGE_STATUS_RETAILER,
  DELETE_PROFILE_DETAILS,
  DELETE_PROFILE_SALES,
  DELETE_PROFILE_RETAILER,
  UPADTE_ADMIN_CUSTOMER,
  UPADTE_SALES_CUSTOMER,
  UPADTE_RETAILER_CUSTOMER,
  GET_DSP_USER,
  GET_ADMIN_USER_1,
  GET_DSP_USER_1,
  GET_DSP_USER_RETAILER,
  INSERT_PROFILE,
  GET_ADMIN_USER_LIST,
  DELETE_ADMIN_USER,
  CHANGE_ADMIN_STATUS,
} from 'redux/admin-constants';

const initialState = {
  dspUserList: [],
  dspUserDetails: [],
  childUserDetails: [],
  childRetailerUserDetails: [],
  adminuser: [],
  adminUserList: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DSP_USER_LIST: {
      return {
        ...state,
        dspUserList: payload,
      };
    }
    case CHANGE_STATUS: {
      return {
        ...state,
        dspUserList: state.dspUserList.map((item) =>
          item.user_id === parseInt(payload.userid)
            ? { ...item, ...payload }
            : item
        ),
      };
    }
    case CHANGE_STATUS_SALES: {
      return {
        ...state,
        childUserDetails: state.childUserDetails.map((item) =>
          item.user_id === parseInt(payload.userid)
            ? { ...item, ...payload }
            : item
        ),
        dspUserList: state.dspUserList.map((item) =>
          item.user_id === parseInt(payload.userid)
            ? { ...item, ...payload }
            : item
        ),
      };
    }
    case CHANGE_STATUS_RETAILER: {
      return {
        ...state,
        childRetailerUserDetails: state.childRetailerUserDetails.map((item) =>
          item.user_id === parseInt(payload.userid)
            ? { ...item, ...payload }
            : item
        ),
        dspUserList: state.dspUserList.map((item) =>
          item.user_id === parseInt(payload.userid)
            ? { ...item, ...payload }
            : item
        ),
      };
    }
    case DELETE_PROFILE_DETAILS:
      const updatedUserList = state.dspUserList.filter(
        (item) => item.user_id !== parseInt(payload.userid)
      );
      return {
        ...state,
        dspUserList: updatedUserList,
      };
    case DELETE_PROFILE_SALES:
      const updatedUserList1 = state.childUserDetails.filter(
        (item) => item.user_id !== parseInt(payload.userid)
      );
      return {
        ...state,
        childUserDetails: updatedUserList1,
        dspUserList: state.dspUserList.filter(
          (item) => item.user_id !== parseInt(payload.userid)
        ),
      };
    case DELETE_PROFILE_RETAILER:
      const updatedUserList2 = state.childRetailerUserDetails.filter(
        (item) => item.user_id !== parseInt(payload.userid)
      );
      return {
        ...state,
        childRetailerUserDetails: updatedUserList2,
        dspUserList: state.dspUserList.filter(
          (item) => item.user_id !== parseInt(payload.userid)
        ),
      };

    case UPADTE_ADMIN_CUSTOMER:
      return {
        ...state,
        dspUserList: state.dspUserList.map((item) =>
          item.user_id === parseInt(payload[0].user_id)
            ? { ...item, ...payload[0] }
            : item
        ),
        childUserDetails: state.childUserDetails.map((item) =>
          item.user_id === parseInt(payload[0].user_id)
            ? { ...item, ...payload[0] }
            : item
        ),
        childRetailerUserDetails: state.childRetailerUserDetails.map((item) =>
          item.user_id === parseInt(payload[0].user_id)
            ? { ...item, ...payload[0] }
            : item
        ),
      };

    case GET_DSP_USER: {
      return {
        ...state,
        dspUserDetails: payload,
      };
    }

    case GET_DSP_USER_1: {
      return {
        ...state,
        childUserDetails: payload,
      };
    }
    case GET_DSP_USER_RETAILER: {
      return {
        ...state,
        childRetailerUserDetails: payload,
      };
    }
    case GET_ADMIN_USER_1: {
      return {
        ...state,
        adminuser: payload,
      };
    }

    case INSERT_PROFILE: {
      return { ...state, dspUserDetails: [payload, ...state.dspUserDetails] };
    }

    case GET_ADMIN_USER_LIST: {
      return {
        ...state,
        adminUserList: payload,
      };
    }

    case CHANGE_ADMIN_STATUS: {
      console.log(payload);
      return {
        ...state,
        adminUserList: state.adminUserList.map((item) =>
          item.user_id === parseInt(payload.userid)
            ? { ...item, ...payload }
            : item
        ),
      };
    }
    case DELETE_ADMIN_USER:
      const updatedAdminUserList = state.adminUserList.filter(
        (item) => item.user_id !== parseInt(payload.userid)
      );
      return {
        ...state,
        dspUserList: updatedAdminUserList,
      };

    default:
      return state;
  }
};

export default reducer;
