import {
  GET_ALL_USERS,
  GET_RETAILER_LIST,
  GET_RETAILER_LIST_1,
  MANAGE_RETAILER_SWAP,
  GET_RETAILER_SWAPPED_LIST,
} from 'redux/admin-constants';

const initialState = {
  allUsersData: [],
  retailerList: [],
  retailerList_1: [],
  retailerSwapData: [],
  swappedRetailerData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_USERS: {
      return {
        ...state,
        allUsersData: payload,
      };
    }

    case GET_RETAILER_LIST: {
      return {
        ...state,
        retailerList: payload,
      };
    }

    case GET_RETAILER_LIST_1: {
      return {
        ...state,
        retailerList_1: payload,
      };
    }

    case MANAGE_RETAILER_SWAP: {
      return {
        ...state,
        retailerSwapData: payload,
      };
    }

    case GET_RETAILER_SWAPPED_LIST: {
      return {
        ...state,
        swappedRetailerData: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
