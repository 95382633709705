import {
    SIM_SWAP,TRANFER_BALANCE
  } from 'redux/retailer-constants';
  
  const initialState = {
    swapData :[] ,
    transferData :[],
  };

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case SIM_SWAP : {
        return {
          ...state,
          swapData : payload,
        };
      }
      case TRANFER_BALANCE : {
        return {
          ...state,
          transferData  : payload,
        };
      }
  
      default:
        return state;
    }
  };
  
  export default reducer;