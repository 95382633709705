import {
  SALES_REPORT_DETAILS,
  SALES_REPORT_PDF,
  PROFIT_REPORT_DETAILS,
  PROFIT_REPORT_PDF,
  MYACCOUNT_REPORT_DETAILS,
  SIM_PROFIT_REPORT_DETAILS,
  TOPUP_PROFIT_REPORT_DETAILS,
  GET_ADMIN_VOUCHER_SEARCH_REPORT,
} from '../constants';

const initialState = {
  sales_report: [],
  sales_report_pdf: [],
  profit_report: [],
  profit_report_pdf: [],
  my_account_report: [],
  sim_profit_report: [],
  my_account_report: [],
  top_up_report: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SALES_REPORT_DETAILS: {
      return {
        ...state,
        sales_report: payload,
      };
    }
    case SALES_REPORT_PDF: {
      return {
        ...state,
        sales_report_pdf: payload,
      };
    }
    case PROFIT_REPORT_DETAILS: {
      return {
        ...state,
        profit_report: payload,
      };
    }
    case PROFIT_REPORT_PDF: {
      return {
        ...state,
        profit_report_pdf: payload,
      };
    }
    case SIM_PROFIT_REPORT_DETAILS: {
      return {
        ...state,
        sim_profit_report: payload,
      };
    }
    case TOPUP_PROFIT_REPORT_DETAILS: {
      return {
        ...state,
        top_up_report: payload,
      };
    }
    case MYACCOUNT_REPORT_DETAILS: {
      return {
        ...state,
        my_account_report: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
