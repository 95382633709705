import {
  BATCH_FORMAT_DATA,
  BATCH_DETAILS_DATA,
  GET_TARIFF,
  PIN_BATCH_STATUS,
  MANAGE_PIN_REMOVE,
  BATCH_PIN_NOTIFICATION
} from 'redux/admin-constants';

const initialState = {
  batchFormatID: [],
  batchDetails: [],
  tariffData: [],
  batch_status: [],
  batch_pin_notification: {
    message: '',
    isSuccess: 1,
    duplicateValues: null
  },
};
const reducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case BATCH_FORMAT_DATA: {
      return {
        ...state,
        batchFormatID: payload,
      };
    }
    case BATCH_DETAILS_DATA: {
      return {
        ...state,
        batchDetails: payload,
      }
    }
    case GET_TARIFF: {
      return {
        ...state,
        tariffData: payload,
      };
    }
    case PIN_BATCH_STATUS: {
      return {
        ...state,
        batch_status: payload,
      }
    }
    case MANAGE_PIN_REMOVE:
      return {
        ...state,
        batch_status: state.batch_status.filter(item => !payload?.info.map((data) => data.batchID).includes(item.batch_id))
    }
    case BATCH_PIN_NOTIFICATION:
      return {
        ...state,
        batch_pin_notification: (payload.message) ? payload : initialState.batch_pin_notification
      }

    default:
      return state;
  }
};

export default reducer;
