import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import auth from './auth/reducer';
import common from './common/reducer';
import questions from './Registration/reducer';
import batchsummary from './admin/ManageBatch/reducer';
import invoice from './admin/Invoice/reducer';
import bannerManagement from './admin/BannerManagement/reducer';
import userMenus from './admin/UserMenu/reducer';
import userWorkGroup from './admin/UserWorkGroup/reducer';
import workGroupMenu from './admin/WorkGroupMenu/reducer';
import voucherConfigDatas from './admin/TariffPlanMapping/reducer';
import ticketPriceData from './admin/TicketPrice/reducer';
import configAuthService from './admin/Configuration/reducer';
import userActivatedList from './admin/useractivation/reducer';
import simStock from './admin/SimInventory/reducer';
import userDetails from './admin/RegisteredUser/reducer';
import profileData from './admin/Profile/reducer';
import batchDetails from './admin/Batch/reducer';
import tarrifData from './admin/TarrifPlan/reducer';
import tarrif_details_notification from './admin/TarrifPlan/reducer';
import registeredUser from './admin/RegisteredUser/reducer';
import voucherProduct from './admin/TariffPlanMapping/reducer';
import mapping_details_notification from './admin/TariffPlanMapping/reducer';
import mailOption from './admin/mailoption/reducer';
import changeProfilePassword from './admin/Profile/reducer';
import simActivation from './Retailer/SimActivation/reducer';
import profile_dettails_notification from './admin/Profile/reducer';
import WorkGropuMapping from './admin/WorkGroupMapping/reducer';
import update_customer_notification from './admin/Profile/reducer';
import delete_profile_notification from './admin/Profile/reducer';
import delete_guest_notification from './admin/GuestAdmin/reducer';
import locked_user from './admin/UserLocked/reducer';
import userData from './admin/PriceSetting/reducer';
import guestAdminData from './admin/GuestAdmin/reducer';
import pinDeleteSummary from './admin/PinDeleteSummary/reducer';
import vouchersale from './Retailer/Voucher/reducer';
import retailerServices from './Retailer/Services/reducer';
import retailerShopUser from './Retailer/useraccount/reducer';
import voucherSearchReport from './admin/VoucherSearchReport/reducer';
import voucherStockReport from './admin/VoucherStockReport/reducer';
import reportData from './report/reducer';
import simActivationListReport from './admin/SimActivated_TopupList/reducer';
import CustomerCreditBalance from './admin/CustomerCreditBalance/reducer';
import retailerReport from './Retailer/Report/reducer';
import bulkOrder from './bulk/reducer';
import simAndVoucherSaleReport from './admin/SimAndVoucherSale/reducer';
import topupReport from './admin/TopupReport/reducer';
import bulkPin from './admin/BulkPinDownload/reducer';
import adminReport from './admin/Report/reducer';
import endUser from './admin/EndUserDetails/reducer';
import TodaysStockReportData from './admin/TodaysStock/reducer';
import performance from './performance/reducer';
import simSearchData from './admin/SimSearch/reducer';
import PreactivateSim from './admin/SimPreactivation/reducer';
import marquee from './admin/marqueeoption/reducer';
import customerInfo from './Retailer/CustomerInfo/reducer';
import subscriberReport from './admin/SubscriberBaseReport/reducer';
import managePriceData from './admin/ManagePrice/reducer';
import userAlert from './admin/usernotification/reducer';
import mnpPortInRequest from './Retailer/PortIn/reducer';
import adminShopUser from './admin/useraccount/reducer';
import retailerSwapData from './admin/RetailerSwap/reducer';
import mnpProviderData from './admin/MNP/reducer';
import mobilebanner from './admin/MobileBanner/reducer';

const appReducer = combineReducers({
  menu,
  settings,
  auth,
  common,
  questions,
  bannerManagement,
  userMenus,
  userWorkGroup,
  workGroupMenu,
  batchsummary,
  bannerManagement,
  voucherConfigDatas,
  ticketPriceData,
  userActivatedList,
  configAuthService,
  simStock,
  WorkGropuMapping,
  userDetails,
  profileData,
  batchDetails,
  tarrifData,
  tarrif_details_notification,
  mailOption,
  registeredUser,
  voucherProduct,
  mapping_details_notification,
  changeProfilePassword,
  simActivation,
  profile_dettails_notification,
  update_customer_notification,
  delete_profile_notification,
  delete_guest_notification,
  locked_user,
  userData,
  vouchersale,
  guestAdminData,
  pinDeleteSummary,
  retailerServices,
  voucherSearchReport,
  voucherStockReport,
  simActivationListReport,
  CustomerCreditBalance,
  retailerReport,
  reportData,
  bulkOrder,
  simAndVoucherSaleReport,
  topupReport,
  bulkPin,
  adminReport,
  endUser,
  performance,
  TodaysStockReportData,
  invoice,
  simSearchData,
  retailerShopUser,
  PreactivateSim,
  managePriceData,
  marquee,
  customerInfo,
  subscriberReport,
  userAlert,
  mnpPortInRequest,
  adminShopUser,
  retailerSwapData,
  mnpProviderData,
  mobilebanner,
});

const reducers = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'DESTROY_SESSION') state = undefined;

  return appReducer(state, action);
};

export default reducers;
