import { GET_ALL_MENUS, INSERT_USER_MENU, UPDATE_USER_MENU, GET_USER_MENU, DELETE_USER_MENU } from 'redux/admin-constants';

const initialState = { 
  userMenuData:[],
  allMenuDatas: [],
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {

  case GET_ALL_MENUS: {
    return {
      ...state,
      allMenuDatas: payload,
    }
  }

  case GET_USER_MENU: {
    return {
      ...state,
      userMenuData: payload,
    }
  }

  case INSERT_USER_MENU: {
    return { ...state, allMenuDatas:[...state.allMenuDatas, payload] }
  }

  case UPDATE_USER_MENU: {
    return {
      ...state, 
      allMenuDatas:
       state.allMenuDatas.map(item => item.menuid === payload.menuid ? 
        { ...item, ...payload} : item 
      )
    }
  }

  case DELETE_USER_MENU:
    return { ...state, 
      allMenuDatas: state.allMenuDatas.filter(item => item.menuid !== payload.menuid)
  }

  default:
    return state;
  }
}

export default reducer;