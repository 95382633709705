import { GET_CUSTOMER_INFO } from 'redux/retailer-constants';

const initialState = {
  customerInfoData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CUSTOMER_INFO: {
      return {
        ...state,
        customerInfoData: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;