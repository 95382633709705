import {
  GET_RETAILER_ADMIN,
  GET_RETAILER_INFORMATION_ADMIN,
  UPDATE_RETAILER_USER_ADMIN,
  DELETE_USER_ADMIN,
} from '../../admin-constants';

const initialState = {
  userList: [],
  updatedUserList: {},
  retailer: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_RETAILER_ADMIN: {
      return {
        ...state,
        userList: payload,
      };
    }
    case GET_RETAILER_INFORMATION_ADMIN: {
      return {
        ...state,
        retailer: payload,
      };
    }
    case UPDATE_RETAILER_USER_ADMIN: {
      return {
        ...state,
        updatedUserList: payload,
      };
    }
    case DELETE_USER_ADMIN: {
      return {
        ...state,
        userList: state.userList.filter(
          (item) => item.user_id !== parseInt(payload.userid)
        ),
      };
    }

    default:
      return state;
  }
};

export default reducer;
