import {
  GET_VOUCHER,PURCHASE_VOUCHER,CHECK_MOBILE_NUMBER, CHANGE_OPTION, DIRECT_PIN_TOPUP
} from 'redux/retailer-constants';

const initialState = {
  voucherList :[],
  purchaseData :[],
  changeOption:{},
  validationData :[],
  directPinTopupData: {}
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case CHANGE_OPTION:{
      return {
        ...state,
        changeOption : payload,
      };
    }
    case GET_VOUCHER : {
      return {
        ...state,
        voucherList : payload,
      };
    }
    case PURCHASE_VOUCHER : {
      return {
        ...state,
        purchaseData : payload,
      };
    }
    case CHECK_MOBILE_NUMBER : {
      return {
        ...state,
        validationData : payload,
      };
    }

    case DIRECT_PIN_TOPUP : {
      return {
        ...state,
        directPinTopupData : payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;