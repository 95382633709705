import { GET_TOPUP_REPORT } from 'redux/admin-constants';
const initialState = {
  topupReportData: [],
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TOPUP_REPORT: {
      return {
        ...state,
        topupReportData: payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;
