import { INSERT_WORKGROUP_MENU, UPDATE_WORKGROUP_MENU, GET_WORKGROUP_MENU, DELETE_WORKGROUP_MENU, GET_WORKGROUP_MENU_LIST } from 'redux/admin-constants';

const initialState = { 
  workGroupMenuData:[],
  workGroupMenuList:[],
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {

  case GET_WORKGROUP_MENU: {
    return {
      ...state,
      workGroupMenuData: payload,
    }
  }

  case INSERT_WORKGROUP_MENU: {
    return { ...state, workGroupMenuData:[...state.workGroupMenuData, payload] }
  }

  case UPDATE_WORKGROUP_MENU: {
    return {
      ...state, 
      workGroupData:
       state.workGroupMenuData.map(item => item.menus.workgroupmenuid === payload.workgroupmenuid ? 
        { ...item, ...payload} : item 
      )
    }
  }

  case DELETE_WORKGROUP_MENU:
    return { ...state, 
        workGroupMenuData: state.workGroupMenuData?.filter(item => item.workgroupid !== payload.workgroupid)
  }

  case GET_WORKGROUP_MENU_LIST: {
    return {
      ...state,
      workGroupMenuList: payload,
    }
  }

  default:
    return state;
  }
}

export default reducer;