import { GET_MANAGEBATCH_SUMMARY, MANAGE_PIN_REMOVE, BATCH_SUMMARY_NOTIFICATION, PIN_SUMMARY_NOTIFICATION,GET_PIN_STATUS,BATCH_PIN_NOTIFICATION } from 'redux/admin-constants';

const initialState = {
  manageBatchsummary: [],
  pinStatus:[],
  batchSummaryNotification:{
    message: '',
    isSuccess: 1
  },
  pinSummaryNotification:{
    message: '',
    isSuccess: 1,
    duplicateValues: null
  },
  batch_pin_notification: {
    message: '',
    isSuccess: 1,
    duplicateValues: null
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case BATCH_PIN_NOTIFICATION:{
      return {
        ...state,
        batch_pin_notification: (payload.message) ? payload : initialState.batch_pin_notification
      };
    }

    case GET_MANAGEBATCH_SUMMARY: {
      return {
        ...state,
        manageBatchsummary: payload,
      };
    }

    case GET_PIN_STATUS: {
      return {
        ...state,
        pinStatus: payload,
      };
    }

    case BATCH_SUMMARY_NOTIFICATION: {
      return {
        ...state,
        batchSummaryNotification: (payload?.message) ? payload : initialState.payload,
      };
    }

    case PIN_SUMMARY_NOTIFICATION: {
      return {
        ...state,
        batchPinNotification: (payload?.message) ? payload : initialState.payload,
      };
    }

    

    default:
      return state;
  }
};

export default reducer;
