import {
  GET_SUBSCRIBER_BASE_REPORT,
  GET_CHURN_REPORT
} from 'redux/admin-constants';

const initialState = {
  subscriberReportData: [],
  churnReportData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUBSCRIBER_BASE_REPORT: {
      return {
        ...state,
        subscriberReportData: payload,
      };
    }
    case GET_CHURN_REPORT: {
      return {
        ...state,
        churnReportData: payload,
      };
    }

    default:
      return state;
  }
};
export default reducer;