import { GET_FIRST_CALLS, GET_PERFORMANCE_TOPUP,GET_BUNDLE_BOOKING,GET_OVERVIEW,GET_OVERVIEW_DOWNLOAD } from '../constants';

const initialState = {
  firstCalls: [],
  performanceTopUp: [],
  bundleBooking:[],
  overview:{},
  download:{},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_FIRST_CALLS: {
      return {
        ...state,
        firstCalls: payload,
      };
    }
    case GET_PERFORMANCE_TOPUP: {
      return {
        ...state,
        performanceTopUp: payload,
      };
    }
    case GET_BUNDLE_BOOKING: {
      return {
        ...state,
        bundleBooking: payload,
      };
    }
    case GET_OVERVIEW: {
      return {
        ...state,
        overview: payload,
      };
    }
    case GET_OVERVIEW_DOWNLOAD: {
      return {
        ...state,
        download : payload,
      };
    }
     
    default:
      return state;
  }
};

export default reducer;
