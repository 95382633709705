import {
  GET_SENT_MAIL,
  GET_DELETE_MAIL,
  GET_INBOX_MAIL,
  GET_EMAIL_IDS,
  POST_EMAIL,
  DELETE_EMAIL,
  UNDO_EMAIL
} from 'redux/admin-constants';

const reducer = (state = {}, action) => {
const {
  type,
  payload
} = action;

switch (type) {
  case GET_SENT_MAIL: {
    return {
      ...state,
      sentMail: payload.data,
      allSentMail: payload.data
    }
  }

  case GET_DELETE_MAIL:{
    return {
      ...state,
      deleteMail: payload.data,
      allDeleteMail:payload.data,
    }
  }

  case GET_INBOX_MAIL:{
    return {
      ...state,
      inboxMail: payload.data,
      allInboxMail:payload.data,
    }
  }

  case GET_EMAIL_IDS:{
    return {
      ...state,
      emailIds: payload.data,
    }
  }

  case POST_EMAIL:{
    return {
      ...state,
      postedEmail: payload,
    }
  }

  case DELETE_EMAIL:{

    if(payload.activetab === 'sent'){
      return {
        ...state,
        deletedEmail: payload,
        sentMail: state.allSentMail?.filter(item1 => !payload?.data?.inboxIds.map((item)=>item)?.includes(item1.mgm_mail_inbox_id))
        }
    }
    else if(payload.activetab === 'inbox'){
      return {
        ...state,
        deletedEmail: payload,
        inboxMail: state.allInboxMail?.filter(item => !payload?.data?.inboxIds.map((item)=>item)?.includes(item.mgm_mail_inbox_id))
        }
    }
    else if(payload.activetab === 'trash'){
      return {
        ...state,
        deletedEmail: payload,
        deleteMail: state.allDeleteMail?.filter(item => !payload?.data?.inboxIds.map((item)=>item)?.includes(item.mgm_mail_inbox_id))
        }
    }
  }

  case UNDO_EMAIL:{
    return {
      ...state,
      undoEmail: payload,
      sentMail: state.allSentMail,
      inboxMail: state.allInboxMail,
      deleteMail: state.allDeleteMail
    }
  }

  default:
    return state;
}
}

export default reducer;