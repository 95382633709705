import {
  GET_USER_TYPE,
  GET_USER_PRICE,
  MANAGE_WEBUSER_PRICE,
  DELETE_WEBUSER_PRICE,
  CHANGE_STATUS,
} from 'redux/admin-constants';

const initialState = {
  userData: [],
  userPriceData: [],
  webUserData: [],
  deleteUserData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER_TYPE: {
      return {
        ...state,
        userData: payload,
      };
    }
    case GET_USER_PRICE: {
      return {
        ...state,
        userPriceData: payload,
      };
    }

    case MANAGE_WEBUSER_PRICE: {
      return {
        ...state,
        webUserData: payload,
      };
    }

    case DELETE_WEBUSER_PRICE: {
      return {
        ...state,
        deleteUserData: payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;
