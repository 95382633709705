import {
  GET_PIN_DELETE_SUMMARY,
  GET_PIN_INDIVIDUAL_SUMMARY,
} from 'redux/admin-constants';

const initialState = {
  summaryData: [],
  pinSummaryData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PIN_DELETE_SUMMARY: {
      return {
        ...state,
        summaryData: payload,
      };
    }
    case GET_PIN_INDIVIDUAL_SUMMARY: {
      return {
        ...state,
        pinSummaryData: payload,
      };
    }
    default:
      return state;
  }
};
export default reducer;
