import { INSERT_USER_WORKGROUP, UPDATE_USER_WORKGROUP, GET_USER_WORKGROUP, DELETE_USER_WORKGROUP, GET_USER_TYPE } from 'redux/admin-constants';

const initialState = { 
  userTypeDetails:[],
  userWorkGroupData:[],
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {

  case GET_USER_WORKGROUP: {
    return {
      ...state,
      userWorkGroupData: payload,
    }
  }

  case GET_USER_TYPE: {
    return {
      ...state,
      userTypeDetails: payload,
    }
  }

  case INSERT_USER_WORKGROUP: {
    return { ...state, userWorkGroupData:[...state.userWorkGroupData, payload] }
  }

  case UPDATE_USER_WORKGROUP: {
    return {
      ...state, 
      userWorkGroupData:
       state.userWorkGroupData.map(item => item.userworkgroupid === payload.userworkgroupid ? 
        { ...item, ...payload} : item 
      )
    }
  }

  case DELETE_USER_WORKGROUP:
    return { ...state, 
        userWorkGroupData: state.userWorkGroupData.filter(item => item.userworkgroupid !== payload.userworkgroupid)
  }

  default:
    return state;
  }
}

export default reducer;