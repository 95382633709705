import {
    GET_VOUCHER_SEARCH_REPORT
  } from 'redux/admin-constants';

  const initialState = {
    searchData: []
  };

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case  GET_VOUCHER_SEARCH_REPORT: {
            return {
              ...state,
              searchData: payload,
            };
          }
        default:
            return state;
    }
};
export default reducer;