import { GET_END_USER, GET_UNIQUE_END_USER, UPDATE_END_USER, SELECTED_END_USER_LOG, END_USER_LOG_COUNT, CLEAR_USER_LOG } from 'redux/admin-constants';

const initialState = {
  endUserReport: [],
  uniqueEndUserReport: [],
  selectedUserLog: [],
  logDataCount: 0,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_END_USER: {
      return {
        ...state,
        endUserReport: payload,
      };
    }
    case GET_UNIQUE_END_USER: {
      return {
        ...state,
        uniqueEndUserReport: payload,
      };
    }
    case UPDATE_END_USER: {
      return {
        ...state, 
        endUserReport:
         state.endUserReport.map(item => item.customer_id === payload.customer_id ? 
          { ...item, ...payload} : item 
        )
      }
    }
    case SELECTED_END_USER_LOG: {
      return {
        ...state,
        selectedUserLog: (payload.isCreation) ? payload : state.selectedUserLog.concat(payload),
      };
    }
    case END_USER_LOG_COUNT: {
      return {
        ...state,
        logDataCount: payload,
      };
    }
    case CLEAR_USER_LOG: {
      return {
        ...state,
        selectedUserLog: payload,
      };
    }
    default:
      return state;
  }
};
export default reducer; 
