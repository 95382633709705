import { INSERT_BANNER, UPDATE_BANNER, GET_BANNER, BANNER_NOTIFICATION, DELETE_BANNER } from 'redux/admin-constants';

const initialState = { 
  bannerDetails:[],
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {

  case GET_BANNER: {
    return {
      ...state,
      bannerDetails: payload,
    }
  }

  case INSERT_BANNER: {
    return { ...state, bannerDetails:[payload, ...state.bannerDetails] }
  }

  case UPDATE_BANNER: {
    return {
      ...state, 
      bannerDetails:
       state.bannerDetails.map(item => item.bannerid === payload.bannerid ? 
        { ...item, ...payload} : item 
      )
    }
  }

  case DELETE_BANNER:
    return { ...state, 
      bannerDetails: state.bannerDetails.filter(item => item.bannerid !== payload.bannerid)
  }

  default:
    return state;
  }
}

export default reducer;