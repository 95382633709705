import { GET_RETAILER,GET_RETAILER_INFORMATION,UPDATE_RETAILER_USER,DELETE_USER} from 'redux/retailer-constants';

const initialState = {
    userList :[],
    retailer:[],
    
  };
  
  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_RETAILER : {
        return {
          ...state,
          userList : payload,
        };
      }
      case GET_RETAILER_INFORMATION : {
        return {
          ...state,
          retailer : payload,
        };
      }
      case UPDATE_RETAILER_USER : {
        return {
          ...state,
          userList  : payload,
        };
      }
      case DELETE_USER : {
        return {
          ...state,
          userList  : state.userList.filter(
            (item) => item.user_id !== parseInt(payload.userid)
          ),
        };
      }
      
  
      default:
        return state;
    }
  };
  
  export default reducer;