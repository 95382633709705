import {
  GET_TARIFF_PLAN,
  INSERT_TARRIF_DETAILS,
  UPDATE_TARRIF_DETAILS,
  TARRIF_DETAILS_NOTIFICATION,
  DELETE_TARRIF_DETAILS,
  TARIFF_PLAN_TYPE,
  TARIFF_PLAN_GROUP,
} from 'redux/admin-constants';
const initialState = {
  tarrifData: [],
  tarrif_details_notification: {
    message: '',
    isSuccess: 1,
  },
  tariffPlanType: [],
  tariffPlanGroup: []
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TARIFF_PLAN: {
      return {
        ...state,
        tarrifData: payload,
      };
    }
    case INSERT_TARRIF_DETAILS: {
      return { ...state, tarrifData: [payload, ...state.tarrifData] };
    }

    case UPDATE_TARRIF_DETAILS: {
      return {
        ...state,
        tarrifData: state.tarrifData.map((item) =>
          item.planid === payload.planid ? { ...item, ...payload } : item
        ),
      };
    }
    case TARRIF_DETAILS_NOTIFICATION:
      return {
        ...state,
        tarrif_details_notification: payload.message
          ? payload
          : initialState.tarrif_details_notification,
      };
    case DELETE_TARRIF_DETAILS:
      return {
        ...state,
        tarrifData: state.tarrifData.filter(
          (item) => item.planid !== payload.planid
        ),
      };

      case TARIFF_PLAN_TYPE: {
        return {
          ...state,
          tariffPlanType: payload,
        };
      }

      case TARIFF_PLAN_GROUP: {
        return {
          ...state,
          tariffPlanGroup: payload,
        };
      }
    default:
      return state;
  }
  
};
export default reducer;
