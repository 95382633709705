import {
  GET_CUSTOMER_BALANCE_REPORT,
  GET_CREDIT_BALANCE_REPORT,
  GET_CREDIT_BALANCE_REPORT_PDF,
  GET_CUSTOMER_BALANCE_REPORT_PDF,
} from 'redux/admin-constants';
const initialState = {
  creditSummary: [],
  creditSummaryPdf: [],
  balanceSummary: [],
  balanceSummaryPdf: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CREDIT_BALANCE_REPORT: {
      return {
        ...state,
        creditSummary: payload,
      };
    }
    case GET_CREDIT_BALANCE_REPORT_PDF: {
      return {
        ...state,
        creditSummaryPdf: payload,
      };
    }

    case GET_CUSTOMER_BALANCE_REPORT: {
      return {
        ...state,
        balanceSummary: payload,
      };
    }

    case GET_CUSTOMER_BALANCE_REPORT_PDF: {
      return {
        ...state,
        balanceSummaryPdf: payload,
      };
    }

    default:
      return state;
  }
};
export default reducer;
