import {
  GET_USER_ALERT,
  INSERT_USER_ALERT,
  UPDATE_USER_ALERT,
  DELETE_USER_ALERT,
  USER_ALERT_NOTIFICATION,
  IMAGE_URL_RESPONSE,
  CHANGE_NOTIFICATION_STATUS
} from 'redux/admin-constants';

const initialState = {
  userAlertMessage: [],
  notification_message:[],
  user_alert_notification: {
    message: '',
    isSuccess: 1,
  },
  imageUrlResponse: [],
  notificationStatus: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_ALERT: {
      return {
        ...state,
        userAlertMessage: payload,
      };
    }

   

    case UPDATE_USER_ALERT: {
      return {
        ...state,
        userAlertMessage: state.userAlertMessage.map((item) =>
          item.popup_notification_id === payload.popup_notificationID
            ? { ...item, ...payload }
            : item
        ),
      };
    }

    case DELETE_USER_ALERT:
      return {
        ...state,
        userAlertMessage: state.userAlertMessage.filter(
          (item) => item.popup_notification_id !== payload
        ),
      };

    case IMAGE_URL_RESPONSE: {
      return {
        ...state,
        imageUrlResponse: payload,
      };
    }
    case  USER_ALERT_NOTIFICATION: {
      return {
        ...state,
        notification_message : payload,
      };
    }

    case CHANGE_NOTIFICATION_STATUS: {
      return {
        ...state,
        notificationStatus: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
