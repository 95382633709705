import {
  GET_VOUHCER_CONFIG,
  MAP_TARRIF_DETAILS,
  GET_VOUHCER_PRODUCT,
  MAPPING_DETAILS_NOTIFICATION,
} from 'redux/admin-constants';

const initialState = {
  voucherConfig: [],
  voucherProduct: [],
  mapping_details_notification: {
    message: '',
    isSuccess: 1,
  },
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_VOUHCER_CONFIG: {
      return {
        ...state,
        voucherConfig: payload,
      };
    }
    case GET_VOUHCER_PRODUCT: {
      return {
        ...state,
        voucherProduct: payload,
      };
    }
    case MAP_TARRIF_DETAILS: {
      return { ...state, voucherConfig: [payload, ...state.voucherConfig] };
    }
    case MAPPING_DETAILS_NOTIFICATION:
      return {
        ...state,
        mapping_details_notification: payload.message
          ? payload
          : initialState.mapping_details_notification,
      };

    default:
      return state;
  }
};

export default reducer;
