import {
  GET_COUNTRIES,
  PREVIEW_DATA,
  COMMON_NOTIFICATION,
  GET_CUSTOMER_BALANCE,
  GET_SECURITY_QUESTIONS,
} from '../constants';

const initialState = {
  countries: [],
  newData: {},
  common_notification: {
    message: '',
    isSuccess: 1,
  },
  customerBalance: 0,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_COUNTRIES: {
      return {
        ...state,
        countries: payload,
      };
    }

    case PREVIEW_DATA: {
      return {
        ...state,
        newData: payload,
      };
    }

    case COMMON_NOTIFICATION:
      return {
        ...state,
        common_notification: payload.message
          ? payload
          : initialState.common_notification,
      };

    case GET_CUSTOMER_BALANCE:
      return {
        ...state,
        customerBalance: payload?.balance
          ? payload?.balance
          : initialState.customerBalance,
      };

    default:
      return state;
  }
};

export default reducer;
