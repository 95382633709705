const { ACTIVATED_USER, INACTIVE_USER, DELETED_INACTIVATE_USER } = require("redux/admin-constants");
  
const initialState = { 
    activated_user: [],
    inactive_user: []
}
  
const reducer = (state = initialState,  action) => {
  const { type, payload } = action;
  
  switch (type) {

    case ACTIVATED_USER: {
      return {
        ...state,
        activated_user: payload,
      }
    }
  
    case INACTIVE_USER:{
      return {
        ...state,
        inactive_user: payload,
      }
    }
    
    case DELETED_INACTIVATE_USER:
     
      return { 
        ...state, 
        inactive_user: state.inactive_user.filter(item => { return item.user_mac_id !== payload.userMacID ;}),
       
    }
  
    default:
      return state;
    }
  }
  
  export default reducer;