import {
  GET_GUEST_ADMIN,
  REGISTER_GUEST_ADMIN,
  UPDATE_GUEST_ADMIN,
  DELETE_GUEST_ADMIN,
} from 'redux/admin-constants';

const initialState = {
  guestAdmin: [],
  registerGuest: [],
  updateGuest: [],
  deleteGuest: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GUEST_ADMIN: {
      return {
        ...state,
        guestAdmin: payload,
      };
    }
    case REGISTER_GUEST_ADMIN: {
      return {
        ...state,
        registerGuest: payload,
      };
    }

    case UPDATE_GUEST_ADMIN: {
      return {
        ...state,
        updateGuest: payload,
      };
    }

    case DELETE_GUEST_ADMIN:
      return {
        ...state,
        guestAdmin: state.guestAdmin.filter(
          (item) => item.user_id !== parseInt(payload.userid)
        ),
      };

    default:
      return state;
  }
};

export default reducer;
