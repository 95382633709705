const { LOCKED_USERS, CHANGE_LOCK } = require('redux/admin-constants');

const initialState = {
  locked_user: [],
};
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOCKED_USERS: {
      return {
        ...state,
        locked_user: payload,
      };
    }
    case CHANGE_LOCK:
      return {
        ...state,
        locked_user: state.locked_user.filter(
          (item) => item.userid !== payload.userid
        ),
      };

    default:
      return state;
  }
};
export default reducer;
