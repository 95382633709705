import {
  SEARCH_ICCID_DETAILS,
  BEFORE_ACTIVATION_DETAILS,
  AFTER_ACTIVATION_DETAILS,
} from 'redux/admin-constants';
const initialState = {
  iccidSearchData: [],
  beforeActivationData: [],
  afterActivationData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_ICCID_DETAILS: {
      return {
        ...state,
        iccidSearchData: payload,
      };
    }

    case BEFORE_ACTIVATION_DETAILS: {
      return {
        ...state,
        beforeActivationData: payload,
      };
    }

    case AFTER_ACTIVATION_DETAILS: {
      return {
        ...state,
        afterActivationData: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
