import { DAILY_SALES_REPORT,DASHBOARD_REPORT,GET_PROFIT_REPORT,DAILY_SALES_HISTORY,DAILY_SALES_REPRINT} from 'redux/retailer-constants';

const initialState = {
  dailySale: [],
  dashboardSale:[],
  profitReport:[],
  todaySale:[],
  reprint:[],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DAILY_SALES_REPORT: {
      return {
        ...state,
        dailySale: payload,
      };
    }
    case DASHBOARD_REPORT: {
      return {
        ...state,
        dashboardSale: payload,
      };
    }
    case GET_PROFIT_REPORT: {
      return {
        ...state,
        profitReport: payload,
      };
    }
    case DAILY_SALES_HISTORY: {
      return {
        ...state,
      todaySale: payload,
      };
    }
    case DAILY_SALES_REPRINT: {
      return {
        ...state,
        reprint: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
