import {
  CHANGE_PASSWORD,
  LOGIN_NOTIFICATION,
  SLIDER_POSTERS,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  GET_PROFILE,
  VERIFY_DATA,OTP_NOTIFICATION,VERIFY_OTP,
} from '../constants';

const initialState = {
  loginNotification: {
    message: '',
    isSuccess: 1,
  },
  slider_poster: [],
  userData: [],
  verifyData: [],
  otp_notification:[],
  otp_verify:[],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_NOTIFICATION:
      return {
        ...state,
        loginNotification: payload.message
          ? payload
          : initialState.loginNotification,
      };
    case CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: payload,
      };
    }
    case OTP_NOTIFICATION: {
      return {
        ...state,
        otp_notification: payload,
      };
    }
    case VERIFY_OTP: {
      return {
        ...state,
        otp_verify: payload,
      };
    }

    case SLIDER_POSTERS: {
      return {
        ...state,
        slider_poster: payload,
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordNotification: payload,
      };
    }
    case FORGOT_PASSWORD: {
      return {
        ...state,
        resetPasswordNotification: payload,
      };
    }
    case GET_PROFILE: {
      return {
        ...state,
        userData: payload,
      };
    }
    case VERIFY_DATA: {
      return {
        ...state,
        verifyData: payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
