import moment from 'moment';
import IntlMessages from 'helpers/IntlMessages';
export const UserRole = {
  Admin: 0,
  Editor: 1,
};
/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1200;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'de';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  // { id: 'es', name: 'Español', direction: 'ltr' },
  // { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
  { id: 'de', name: 'Deutsch', direction: 'ltr' },
];

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const adminRoot = '/app';
export const adminUserRoot = '/admin';
export const adminCrmRoot = '/crmadmin';
export const retailerRoot = '/retailer';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';
export const adminCustomRoot = '/customer';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];

export const passwordRegex = {
  atLeastOne: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
  consective: /^(?:(.)(?!\1\1))*$/,
  atleastoneMessage:
    'Password must have min 1 uppercase letter,1 number,1 lowercase letter & min length of 8',
  consectiveMessage: 'Password have no consective 3 chars(ex:aaa/AAA/ZZZ)',
};

export const usernameRegex = {
  characterornumber: /^[a-zA-Z]/,
  space: /^[a-zA-Z0-9.\-_$@*!#]{1,300}$/,
  characterornumbermessage:
    'Starting must not be a special character or number',
  spacemessage: 'No spaces or comma',
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const selectDefaultOption = {
  label: 'Select',
  value: '',
  key: 0,
};

export const defaultFormatDate = (date, join = '-', type = 0) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return type ? [day, month, year].join(join) : [year, month, day].join(join);
};

export const getDate = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('DD-MMM-YYYY');
  return lastValue;
};

export const getDateMonth1 = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('MMMM-YYYY');
  return lastValue;
};

export const getMailDate = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('MMM DD, YYYY HH:mm');
  return lastValue;
};

export const getDate1 = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('DD-MM-YYYY');
  return lastValue;
};
export const apiDate = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('YYYY-MM-DD');
  return lastValue;
};
export const endUserDate = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('DD/MM/YYYY');
  return lastValue;
};

export const converttimestamptodate = (timeStamp_value) => {
  var theDate = new Date(timeStamp_value * 1000);
  return theDate.toGMTString();
};

export const secondsToTime = (secs) => {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    hours: zeroPad(hours, 2),
    mins: zeroPad(minutes, 2),
    sec: zeroPad(seconds, 2),
  };
  return obj;
};

const zeroPad = (num, places) => {
  return String(num).padStart(places, '0');
};

export const flixbusconverttimestamp = (timestamp, numofHours = 2) => {
  let dateObj = new Date(timestamp * 1000);
  let hours = dateObj.getUTCHours().toString().padStart(2, 0);
  hours = parseInt(hours) + 2;
  hours = hours.toString().padStart(2, 0);
  let minutes = dateObj.getUTCMinutes().toString().padStart(2, 0);
  let result = `${hours}:${minutes}`;
  return result;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateEmailid = (value) => {
  let error;
  if (!value) {
    error = 'Please enter your email address';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Invalid email address';
  }
  return error;
};

export const timestampconvert = (date, tz, timeonly = 0) => {
  var m = moment(date); // input is local time
  m.utcOffset(tz); // convert to UTC+1
  return timeonly ? m.format('HH:mm') : m.format('DD-MM-YYYY HH:mm');
};

export const resellerRoot = '/app';
export const resellerUserRoot = '/reseller';

// For Mail options

export const quillModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'], // toggled buttons
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }], // custom button values
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { script: 'sub' }, // superscript/subscript
      { script: 'super' },
      { indent: '-1' }, // outdent/indent
      { indent: '+1' },
    ],
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    // ['link', 'image'],
    ['clean'],
  ],
};

export const quillFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

export const getPriceFormatter = (val, cur) => {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  return formatter.format(val);
};

export const getLocalTime = (val) => {
  return moment.utc(val).tz('Europe/Paris').format('DD-MM-YYYY HH:mm:ss');
};

export const getLocalTime1 = (val) => {
  return moment.tz(val).format('DD-MM-YYYY  HH:mm:ss');
};

export const getLocalTimeDateOnly = (val) => {
  return moment.utc(val).local().format('DD-MM-YYYY ');
};

export const floatFormatter = (data) => {
  return parseFloat(data).toFixed(2);
};

export const preventMinus = (e) => {
  if (e.charCode < 48) {
    e.preventDefault();
  }
};

export const ddmmyyyFormat = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('DD-MM-YYYY');
  return lastValue;
};

export const ddmmyyyFormatAdddate = (val, add) => {
  var moment = require('moment');
  var d = new Date(val);
  d.setDate(d.getDate() + parseInt(add));
  const lastValue = moment(d).format('DD-MM-YYYY');
  return lastValue;
};

export const ddmmyyyFormat1 = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('DD-MM-YYYY HH:mm:ss');
  return lastValue;
};

export const MultiSelectStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? 'blue' : 'blue',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected ? 'blue' : 'blue',
      },
    };
  },
};
