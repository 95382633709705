import { GET_BULKPIN_DOWNLOAD, GET_BULKPIN_EXCEL } from 'redux/admin-constants';

const initialState = {
  bulkPinData: [],
  bulkPinExcelData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_BULKPIN_DOWNLOAD: {
      return {
        ...state,
        bulkPinData: payload,
      };
    }

    case GET_BULKPIN_EXCEL: {
      return {
        ...state,
        bulkPinExcelData: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
