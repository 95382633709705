import { MANAGE_PRICE } from 'redux/admin-constants';

const initialState = {
  managePricesummary: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MANAGE_PRICE: {
      return {
        ...state,
        managePricesummary: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
