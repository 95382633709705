import {
  GET_REGISTEREDUSER,
  GET_DSPUSER,
  REG_USER,
  TEMP_USER,
  GET_CSRUSER,
  GET_CRMUSER,
  USER_NOTIFICATION,
  CHANGE_CRM_STATUS,
  CHANGE_CSA_STATUS,
  DELETE_USER_LIST,
  SUB_ADMIN_REGISTER,
  UPADTE_ADMIN_USER,
  GET_CRMUSER_COUNT,
  CRM_USER_UNLOCK
} from 'redux/admin-constants';

const initialState = {
  userDetails: [],
  dspUser: [],
  regUserNotification: [],
  csrDetails: [],
  crmUser: [],
  deletedList: [],
  adminUserDetails: [],
  crmUserCount: 0,
  crmUserUnlock:[],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_REGISTEREDUSER: {
      return {
        ...state,
        userDetails: payload,
      };
    }
    case GET_DSPUSER:
      return {
        ...state,
        dspUser: payload,
      };

    case REG_USER:
      return {
        ...state,
        userDetails: state.userDetails.filter(
          (item) => item.userid !== payload.userID
        ),
      };

    case TEMP_USER: {
      return {
        ...state,
        userDetails: payload,
      };
    }
    case GET_CRMUSER: {
      return {
        ...state,
        crmUser: payload,
      };
    }
    case GET_CSRUSER: {
      return {
        ...state,
        csrDetails: payload,
      };
    }
    case DELETE_USER_LIST: {
      return {
        ...state,
        deletedList: payload,
      };
    }

    case CHANGE_CRM_STATUS: {
      return {
        ...state,
        crmUser: state.crmUser?.map((item) =>
          item.userid === parseInt(payload.userid)
            ? { ...item, ...payload }
            : item
        ),
      };
    }

    case CHANGE_CSA_STATUS: {
      return {
        ...state,
        csrDetails: state.csrDetails?.map((item) =>
          item.userid === parseInt(payload.userid)
            ? { ...item, ...payload }
            : item
        ),
      };
    }

    case USER_NOTIFICATION:
      return {
        ...state,
        regUserNotification: payload?.message
          ? payload
          : initialState.regUserNotification,
      };

    case SUB_ADMIN_REGISTER: {
      return { ...state, adminUserDetails: [payload, ...state.adminUserDetails] };
    }

    case UPADTE_ADMIN_USER:
     
      return {
        ...state,
        adminUserDetails: state.adminUserDetails?.map((item) =>
          item.user_id === parseInt(payload[0].user_id)
            ? { ...item, ...payload[0] }
            : item
        ),
      };

      case GET_CRMUSER_COUNT: {
        return {
          ...state,
          crmUserCount: payload,
        };
      }

      case CRM_USER_UNLOCK: {
        return {
          ...state,
          crmUser: state.crmUser?.map((item) =>
          item.userid === parseInt(payload.userid)
          ? { ...item, islock: 0 }
         : item
          ),
        };
      }


    default:
      return state;
  }
};
export default reducer;
