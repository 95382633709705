import {
  GET_CHECK_SIM,
  GET_PHONE_NUMBER,
  LOCK_PHONE_NUMBER,
  MANAGE_SIM_ACTIVATION,
  GENERATE_QR_CODE,
  ALTEN_TRANSACTIONS,
  ADD_ALTEN_TRANSACTION,
  VERIFY_ALTEN_TRANSACTION,
  GET_STATIC_ICCID,
  GENERATE_MOBILE_PRINTOUT,
  UPDATE_ALTEN_TRANSACTION,
  GET_PORTIN_CUSTOMER_NUMBER,
  ALTEN_INDIVIDUAL_TRANSACTION,
  GET_PORTIN_STATUS,
  GET_PORTOUT_STATUS,
} from 'redux/retailer-constants';

const initialState = {
  validateData: [],
  phoneNumberList: [],
  lockResponse: {},
  activateResponse: {},
  altenQRcode: {},
  altenTransactions: [],
  verifyAltenData: [],
  staticIccid: {},
  mobilePrintout: {},
  portin_customer_number: [],
  alten_individual_transaction: {},
  portInStatusData: [],
  portOutStatusData: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CHECK_SIM: {
      return {
        ...state,
        validateData: payload,
      };
    }
    case GET_PHONE_NUMBER: {
      return {
        ...state,
        phoneNumberList: payload,
      };
    }
    case LOCK_PHONE_NUMBER: {
      return {
        ...state,
        lockResponse: payload,
      };
    }
    case MANAGE_SIM_ACTIVATION: {
      return {
        ...state,
        activateResponse: payload,
      };
    }
    case GENERATE_QR_CODE: {
      return {
        ...state,
        altenQRcode: payload,
      };
    }
    case GENERATE_MOBILE_PRINTOUT: {
      return {
        ...state,
        mobilePrintout: payload,
      };
    }
    case GET_STATIC_ICCID: {
      return {
        ...state,
        staticIccid: payload,
      };
    }

    case ALTEN_TRANSACTIONS: {
      return {
        ...state,
        altenTransactions: payload,
      };
    }

    case ADD_ALTEN_TRANSACTION: {
      return {
        ...state,
        altenTransactions: [payload, ...state.altenTransactions],
      };
    }

    case UPDATE_ALTEN_TRANSACTION: {

      return {
        ...state,
        altenTransactions: state.altenTransactions.map((item) =>
          item.verificationid === payload.token
            ? { ...item, ...payload }
            : item
        ),
      };
    }

    case VERIFY_ALTEN_TRANSACTION: {
      return {
        ...state,
        verifyAltenData: payload,
      };
    }

    case GET_PORTIN_CUSTOMER_NUMBER: {
      return {
        ...state,
        portin_customer_number: [payload, ...state.portin_customer_number],
      };
    }

    case ALTEN_INDIVIDUAL_TRANSACTION: {
      return {
        ...state,
        alten_individual_transaction: payload,
      };
    }

    case GET_PORTIN_STATUS: {
      return {
        ...state,
        portInStatusData: payload,
      };
    }

    case GET_PORTOUT_STATUS: {
      return {
        ...state,
        portOutStatusData: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
