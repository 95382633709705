import { GET_INVOICE_LIST, DELETE_INVOICE_LIST,POST_INVOICE_LIST, GET_ADMIN_INVOICE_REPORT } from 'redux/admin-constants';

const initialState = {
  invoice:[],
  invoiceReport:[],
  invoicePreview:null
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INVOICE_LIST: {
      return {
        ...state,
        invoice: payload,
      };
    }
    case GET_ADMIN_INVOICE_REPORT: {
      return {
        ...state,
        invoiceReport: payload,
      }
    }
    case DELETE_INVOICE_LIST: {
      return {
        ...state,
        invoiceReport: state. invoiceReport.filter(
          (item) =>  item.invoice_number !== payload.invoiceNumber
        ),
      }
    }
    case POST_INVOICE_LIST: {
      return {
        ...state,
        invoice: state.invoice.filter(
          (item) => !payload.userIDS.includes(item.user_id)
        ),
      };
    }
    
    default:
      return state;
  }
};
export default reducer;