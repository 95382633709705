import {
  GET_VOUCHER_STOCK_REPORT,GET_VOUCHER_STOCK_REPORT_PER_DATA
  } from 'redux/admin-constants';

  const initialState = {
    searchData: [],
    pinSummaryData:[],
  };

  const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case  GET_VOUCHER_STOCK_REPORT: {
            return {
              ...state,
              stockData: payload,
            };
          }
          case GET_VOUCHER_STOCK_REPORT_PER_DATA: {
            return {
              ...state,
              pinSummaryData: payload,
            };
          }
        default:
            return state;
    }
};
export default reducer;