import {
  DAILY_SALES_REPORT_ADMIN,
  DAILY_SALES_REPORT_DISTRIBUTOR,
  DAILY_SALES_REPORT_GET_RETAILER,
  DAILY_SALES_REPORT_RETAILER,
  GET_ADMIN_VOUCHER_SEARCH_REPORT,
  GET_RETAILER_VOUCHER_SEARCH_REPORT,
} from 'redux/admin-constants';

const initialState = {
  dailySaleAdmin: [],
  dailySaleDistributor: [],
  dailySaleRetailer: [],
  getRetailer: [],
  getAdminVoucher: [],
  getRetailerVoucher: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DAILY_SALES_REPORT_ADMIN: {
      return {
        ...state,
        dailySaleAdmin: payload,
      };
    }

    case DAILY_SALES_REPORT_DISTRIBUTOR: {
      return {
        ...state,
        dailySaleDistributor: payload,
      };
    }

    case DAILY_SALES_REPORT_GET_RETAILER: {
      return {
        ...state,
        getRetailer: payload,
      };
    }

    case DAILY_SALES_REPORT_RETAILER: {
      return {
        ...state,
        dailySaleRetailer: payload,
      };
    }

   

    case GET_ADMIN_VOUCHER_SEARCH_REPORT: {
      return {
        ...state,
        getAdminVoucher: payload,
      };
    }

    case GET_RETAILER_VOUCHER_SEARCH_REPORT: {
      return {
        ...state,
        getRetailerVoucher: payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
