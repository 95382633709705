import { INSERT_MOBILE_BANNER, UPDATE_MOBILE_BANNER, GET_MOBILE_BANNER, DELETE_MOBILE_BANNER, UPDATE_MOBILE_MENU_STATUS, GET_MOBILE_MENU } from 'redux/admin-constants';

const initialState = { 
  mobileBannerDetails:[],
  mobileMenuDetails:[],
}

const reducer = (state = initialState,  action) => {
const {
  type,
  payload
} = action;

switch (type) {

  case GET_MOBILE_BANNER: {
    return {
      ...state,
      mobileBannerDetails: payload,
    }
  }

  case INSERT_MOBILE_BANNER: {
    return { ...state, mobileBannerDetails:[ ...state.mobileBannerDetails,payload] }
  }

  case UPDATE_MOBILE_BANNER: {
    return {
      ...state, 
      mobileBannerDetails:
       state.mobileBannerDetails.map(item => item.bannerid === payload.bannerid ? 
        { ...item, ...payload} : item 
      )
    }
  }

  case DELETE_MOBILE_BANNER:
    return { ...state, 
      mobileBannerDetails: state.mobileBannerDetails.filter(item => item.bannerid !== payload.bannerid)
  }

  case UPDATE_MOBILE_MENU_STATUS: {
    return { ...state, mobileMenuDetails:[ ...state.mobileMenuDetails,payload] }
  }

  case GET_MOBILE_MENU: {
    return {
      ...state,
      mobileMenuDetails: payload,
    }
  }

  default:
    return state;
  }
}

export default reducer;