import {
    CHECK_CONFIG_LOGIN,
    LOGIN_NOTIFICATION,
    CHANGE_PASSWORD, 
    CHECK_CONFIGURATION,
    GET_CREDIT_HISTORY,
    INSERT_CREDITS
    } from 'redux/admin-constants';

    const initialState = { 
      checkconfiglogin: [],
      loginNotification: [],
      creditDetails:[],
  }
  
  const reducer = (state = initialState, action) => {
    const {
      type,
      payload
    } = action;
  
    switch (type) {
      case CHECK_CONFIG_LOGIN: {
        return {
          ...state,
          checkconfiglogin: payload,
        }
      }
      case LOGIN_NOTIFICATION:
        return { ...state, 
          loginNotification: (payload.message) ? payload : null
      }
    
      case CHANGE_PASSWORD: {
        return {
          ...state,
          changePassword: payload,
        }
      }
      case CHECK_CONFIGURATION: {
        return {
          ...state,
          checkConfiguration: payload,
        }
      }
      case GET_CREDIT_HISTORY: {
        return {
          ...state,
          creditDetails: payload,
        }
      }
      case INSERT_CREDITS: {
        return {
          ...state,
          creditDetails: payload,
        }
      }
      default:
          return state;
      }
  }
  export default reducer;